import React from 'react';
import BaseLayout from '../../components/templates/BaseLayout';
import { graphql } from 'gatsby';
import Button from '../../components/atoms/inputs/Button';
import SEO from '../../components/atoms/utility/seo';
import BusinessApplicant from '../../components/organisms/BusinessApplicant';

const staticSchemaMarkupData = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'flockx',
  logo: 'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1679063482/flockx-website/Logos/flockx-logo-google_xb9ted.png',
  image: 'https://res.cloudinary.com/fetch-ai/image/upload/v1736515174/flockx-website/Landing%20page/hero_banner_e2dlsh.png',
  sameAs: [
    'https://twitter.com/flockxofficial',
    'https://flockx.io',
    'https://www.linkedin.com/company/flockxofficial/',
  ],
  keywords: [
    'small business ai',
    'customer service automation',
    'business efficiency',
    'ai assistant',
    'business growth',
    'customer support',
    'business automation',
    'ai for small business',
    'automated customer service',
    'business ai solutions',
    'ai customer support',
    'business productivity',
    'ai business tools',
    'digital business assistant',
    'business process automation'
  ].toString(),
  founder: 'Humayun Sheikh',
  slogan: 'Your Business AI Assistant',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '99 Monroe',
    addressLocality: 'Grand Rapids',
    addressRegion: 'MI',
    postalCode: '49503',
    addressCountry: 'USA',
    floor: '2nd Floor',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 30.405504665093982,
    longitude: -97.72119716931535,
  },
};

const SEO_KEYWORDS = [
  'business ai',
  'customer service automation',
  'business efficiency',
  'ai assistant',
  'business growth',
  'customer support',
  'business automation',
  'ai for small business',
  'automated customer service',
  'business ai solutions',
  'ai customer support',
  'business productivity',
  'ai business tools',
  'digital business assistant',
  'business process automation'
];

const BusinessPage = ({ data: { site: { siteMetadata: { description, siteUrl } } } }) => {
  const heroImageUrl = "https://res.cloudinary.com/fetch-ai/image/upload/v1736515174/flockx-website/Landing%20page/hero_banner_e2dlsh.png";

  const updatedSchemaMarkup = {
    ...staticSchemaMarkupData,
    description,
    url: siteUrl,
    image: heroImageUrl
  };

  const handleCTAButtonClick = () => {
    if (typeof window !== 'undefined') {
      window?.gtag('event', 'business_cta_click', {
        event_category: 'CTA',
        event_label: 'Business Landing CTA',
      });
      window.location.href = 'https://community.flockx.io/api/auth/login';
    }
  };

  return (
    <BaseLayout
      mainSectionClass="relative flex-grow h-full w-full"
      hideHeroImage
      customHeaderClass="fixed w-full z-50 bg-transparent"
    >
      <SEO
        title="Your Business's AI Assistant - No Tech Skills Required"
        keywords={SEO_KEYWORDS}
        description="Create Your Own Business AI Assistant. Transform Your Business Knowledge Into 24/7 Customer Support. Perfect for Small Business Owners Who Want to Scale Their Operations."
        image={heroImageUrl}
        schemaMarkup={updatedSchemaMarkup}
      />

    <div className="relative h-screen">
      {/* Background with class for media query handling */}
      <div className="hero-background" />

      {/* Content container with existing Tailwind classes */}
      <div className="absolute inset-0 z-10 flex pt-24">
        <div className="w-full flex">
          <div className="w-full px-2 lg:px-6 md:pl-24 lg:pl-32 
            flex items-end md:items-center 
            pb-[15vh] lg:pb-100"
          >
            <div className="w-full mx-auto md:mx-0 
              max-w-xl md:max-w-2xl 2xl:max-w-4xl
              text-center md:text-left"
            >
              <h1 className="text-2xl md:text-4xl lg:text-5xl 2xl:text-6xl 
                font-bold tracking-regular text-white 
                mb-4 md:mb-6 leading-relaxed md:leading-relaxed lg:leading-relaxed"
              >
            Your Business AI Agent <br></br>No Code Required
          </h1>
              
              <p className="text-base md:text-lg lg:text-xl 2xl:text-2xl 
                font-regular text-white/90 
                mb-6 md:mb-8 
                leading-7 md:leading-8 lg:leading-9"
              >
           Give Your Business an AI Agent That Never Sleeps. Handle Customer Questions, Manage Bookings, and Share Information Automatically.
           </p>
           <p className="text-base md:text-lg lg:text-xl 2xl:text-2xl 
                font-regular text-white/90 
                mb-6 md:mb-8 
                leading-7 md:leading-8 lg:leading-9"
              >
           Ready to transform your business? Join business owners who have already streamlined their operations, and get early access today.
              </p>

              <div className=""></div>
        <BusinessApplicant />
        <div className=""></div>{' '}
            </div>
          </div>
        </div>
      </div>
    </div>



    </BaseLayout>
  );
}


export const query = graphql`
  query BusinessPageQuery {
    site {
      siteMetadata {
        description
        siteUrl
      }
    }
  }
`;

export default BusinessPage;
